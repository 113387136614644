import * as React from 'react'
import { FormattedMessage } from 'react-intl';


import IndexLayout from '../layouts'
import LilaSection from '../components/LilaSection'
import styled from '@emotion/styled'

import PmBook from '../components/pm/PmBook'
import PmNav from '../components/nav/PmNav'
import { fonts } from '../styles/variables'
var Scroll = require('react-scroll');
var Element = Scroll.Element;



const Chapter = styled.div`
width:100%;
display:flex;
padding-left: 8rem;
padding-right: 12rem;
padding-top: 4rem;
text-align:left;

 @media screen and (max-width: 1199px) {
padding-left: 10%;
padding-right: 10%;

}
`

const ChapterText = styled.div`
width:70%;
margin-bottom:4rem;

 @media screen and (max-width: 1199px) {

width:100%;

}


`

const SubP = styled.p`
text-align: left;
margin-top:2rem;
margin-bottom:0.7rem;
letter-spacing:1px;
font-size:0.9rem !important;
font-family:${fonts.bcExtraLight};
`

const P = styled.p`
text-align: left;
letter-spacing:1px;
font-size:1rem !important;
margin-bottom:0px;
padding-bottom:0px;
display:flex;
`



const H1 = styled.h1`
font-size:2rem;
`



const Zahlungsarten = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <PmBook title={<FormattedMessage id={'methods_0'}/>} subTitle1={''} subTitle2={''}/>


    <Element id="chOne"/>
    <div id={'section-1'}>
      <LilaSection id={'first'} title={''}></LilaSection>
      <Chapter id={'chapter1'}>
        <PmNav />
        <ChapterText>
          <H1><FormattedMessage id={'pm_header_1'}/>: </H1>
          <SubP><FormattedMessage id={'ps_sub_1'}/></SubP>
          <P> <FormattedMessage id={'cards_1'}/></P>
          <SubP><FormattedMessage id={'ps_sub_2'}/></SubP>
          <P> <FormattedMessage id={'cards_2'}/></P>
          <SubP><FormattedMessage id={'ps_sub_3'}/></SubP>
          <P> <FormattedMessage id={'cards_3'}/></P>
          <SubP><FormattedMessage id={'ps_sub_4'}/></SubP>
          <P> <FormattedMessage id={'immediate'}/></P>


        </ChapterText>
      </Chapter>
    </div>

    <Element id="chTwo"/>
    <div id={'section-2'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter2'}>
        <PmNav />
        <ChapterText>
          <H1><FormattedMessage id={'pm_header_2'}/> </H1>

          <SubP><FormattedMessage id={'ps_sub_2'}/></SubP>
          <P><FormattedMessage id={'paypal_1'}/></P>
          <SubP><FormattedMessage id={'ps_sub_3'}/></SubP>

          <P><FormattedMessage id={'paypal_2'}/></P>
          <SubP><FormattedMessage id={'ps_sub_4'}/></SubP>

          <P><FormattedMessage id={'immediate'}/></P>

        </ChapterText>
      </Chapter>

    </div>


    <Element id="chThree"/>
    <div id={'section-3'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter3'}>
        <PmNav />
        <ChapterText>
          <H1> <FormattedMessage id={'pm_header_3'}/></H1>
          <SubP><FormattedMessage id={'ps_sub_2'}/></SubP>
          <P><FormattedMessage id={'giropay_1'}/></P>
          <SubP><FormattedMessage id={'ps_sub_3'}/></SubP>
          <P><FormattedMessage id={'giropay_2'}/></P>
          <SubP><FormattedMessage id={'ps_sub_4'}/></SubP>
          <P><FormattedMessage id={'immediate'}/></P>




        </ChapterText>
      </Chapter>
    </div>

  <Element id="chFour"/>
    <div id={'section-4'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter4'}>
        <PmNav />
        <ChapterText>
          <H1> <FormattedMessage id={'pm_header_4'}/></H1>
          <SubP><FormattedMessage id={'ps_sub_5'}/></SubP>
          <P><FormattedMessage id={'klarna_1'}/></P>
          <SubP><FormattedMessage id={'ps_sub_4'}/></SubP>
          <P><FormattedMessage id={'klarna_2'}/></P>





        </ChapterText>
      </Chapter>
    </div>

  <Element id="chFive"/>
    <div id={'section-5'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter5'}>
        <PmNav />
        <ChapterText>
          <H1> <FormattedMessage id={'pm_header_5'}/></H1>
          <SubP><FormattedMessage id={'ps_sub_2'}/></SubP>
          <P><FormattedMessage id={'ideal_1'}/></P>
          <SubP><FormattedMessage id={'ps_sub_3'}/></SubP>
          <P><FormattedMessage id={'ideal_2'}/></P>
          <SubP><FormattedMessage id={'ps_sub_4'}/></SubP>
          <P><FormattedMessage id={'immediate'}/></P>




        </ChapterText>
      </Chapter>
    </div>


  <Element id="chSix"/>
    <div id={'section-6'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter6'}>
        <PmNav />
        <ChapterText>
          <H1> <FormattedMessage id={'pm_header_6'}/></H1>
          <SubP><FormattedMessage id={'ps_sub_6'}/></SubP>
          <P><FormattedMessage id={'bitcoin_1'}/></P>
          <SubP><FormattedMessage id={'ps_sub_4'}/></SubP>
          <P><FormattedMessage id={'immediate'}/></P>




        </ChapterText>
      </Chapter>
    </div>

  <Element id="chSeven"/>
    <div id={'section-7'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter7'}>
        <PmNav />
        <ChapterText>
          <H1> <FormattedMessage id={'pm_header_7'}/></H1>
          <SubP><FormattedMessage id={'ps_sub_2'}/></SubP>
          <P><FormattedMessage id={'bank_transfer_1'}/></P>
          <SubP><FormattedMessage id={'ps_sub_3'}/></SubP>
          <P><FormattedMessage id={'bank_transfer_2'}/></P>
          <SubP><FormattedMessage id={'ps_sub_4'}/></SubP>
          <P><FormattedMessage id={'bank_transfer_3'}/></P>




        </ChapterText>
      </Chapter>
    </div>


  </IndexLayout>
)

export default Zahlungsarten
